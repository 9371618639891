module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#FFF","display":"standalone","icon":"src/images/favicon.png","logo":"src/images/uvas-sharing.jpg","name":"Uvas. Бухгалтерия нового поколения","short_name":"Uvas app","start_url":"/","theme_color":"#38C9F2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-yandex-metrica/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"14427034","clickmap":true,"trackLinks":true,"accurateTrackBounce":true,"trackHash":true,"webvisor":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
