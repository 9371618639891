// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-offers-jsx": () => import("./../src/templates/offers.jsx" /* webpackChunkName: "component---src-templates-offers-jsx" */),
  "component---src-templates-services-jsx": () => import("./../src/templates/services.jsx" /* webpackChunkName: "component---src-templates-services-jsx" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-overview-jsx": () => import("./../src/pages/about/overview.jsx" /* webpackChunkName: "component---src-pages-about-overview-jsx" */),
  "component---src-pages-about-requisites-jsx": () => import("./../src/pages/about/requisites.jsx" /* webpackChunkName: "component---src-pages-about-requisites-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-reviews-jsx": () => import("./../src/pages/reviews.jsx" /* webpackChunkName: "component---src-pages-reviews-jsx" */)
}

